<template>
  <!-- <div class="sm:inline-flex"> -->
  <div>
    <div class="col-11 div-center">
      <BlockUI class="pb-2">
        <Card>
          <template #title> Ficha Financeira </template>
          <template #content>
            <div class="p-fluid grid">
              <div v-if="folhasList.length > 1" class="field col-12 md:col-2">
                <span class="p-float-label">
                  <Dropdown v-model="folha" :options="folhasList" />
                  <label for="folha">Folha</label>
                </span>
              </div>
              <div class="field col-12 md:col-2">
                <span class="p-float-label">
                  <Dropdown v-model="exercicio" :options="exerciciosList" />
                  <label for="exercicioFim">Exercício</label>
                </span>
              </div>
              <div class="field col-12 md:col-2" hidden>
                <span class="p-float-label">
                  <Dropdown
                    v-model="exercicioInicio"
                    :options="exerciciosInicioList"
                    @change="listarExericiosFimESelecionarExericioFim()" />
                  <label for="exercicioInicio">Exercício Início</label>
                </span>
              </div>

              <div class="field col-12 md:col-2" hidden>
                <span class="p-float-label">
                  <Dropdown
                    v-model="exercicioFim"
                    :options="exerciciosFimList" />
                  <label for="exercicioFim">Exercício Fim</label>
                </span>
              </div>
              <div class="field col-12 md:col-2">
                <Button
                  label="Gerar Ficha"
                  :disabled="exercicio == null"
                  @click="obterFichaFinanceira()" />
              </div>
            </div>
          </template>
        </Card>
      </BlockUI>
      <BlockUI :blocked="showLoading">
        <Card v-if="showFichaFinanceira == true" class="contracheque">
          <template #title>
            <div class="row">
              <div class="col-auto d-flex align-items-center">
                <Button
                  icon="pi pi-arrow-left"
                  class="p-button-rounded p-button-plain p-button-outlined"
                  @click="retornar()" />
              </div>
              <div class="col">
                <Breadcrumb :home="home" :model="breadcrumbitems" />
              </div>
            </div>
            <div class="row flex-row-reverse text-lg">
              <span
                style="margin: 15px; color: #071d41"
                class="exercicio_label text-base font-medium"
                >Exercício: {{ exercicio }}</span
              >
            </div>
            <div
              class="row d-flex align-items-end"
              style="border-bottom: 1px solid #efefef">
              <div class="col pl-8">
                <img
                  v-if="contracheque.orgao == 'CODATA'"
                  src="@/img/logo-codata.png"
                  class="mt-2 mb-2"
                  style="
                    max-width: 200px;
                    max-height: 200px;
                    display: inline-block;
                  " />
                <img
                  v-if="imagemSrc == null"
                  src="@/img/logo-pb.png"
                  style="
                    max-width: 200px;
                    max-height: 200px;
                    display: inline-block;
                  " />
                <img
                  v-if="contracheque.orgao != 'CODATA'"
                  :src="imagemSrc"
                  style="max-width: 50px; max-height: 50px" />
              </div>
              <div class="col d-flex flex-row-reverse h-50">
                <Button
                  style="background-color: #071d41"
                  @click="realizarDownload()">
                  Imprimir
                </Button>
              </div>
            </div>
          </template>
          <template #content>
            <div class="contanier">
              <div class="row">
                <div class="col d-flex flex-column overflow-x-scroll">
                  <table
                    id="ficha_financeira_header"
                    class="ficha_fin_table overflow-x-scroll"
                    style="color: #071d42">
                    <thead class="border-transparent">
                      <tr class="table">
                        <th
                          class="lancamentosHeader text-center text-base p-2"
                          style="width: 100%; min-width: 380px"
                          colspan="6">
                          FICHA FINANCEIRA PESSOAL
                        </th>
                      </tr>
                      <tr
                        style="border: none !important"
                        class="head-line md:vertical-align-middle">
                        <th
                          v-for="(col, index) in [
                            {
                              label: 'Servidor',
                              value: fichaFinHeader.nome,
                              width: '30%',
                              colspan: 1,
                            },
                            {
                              label: 'Cargo',
                              value: fichaFinHeader.cargo,
                              width: '25%',
                              colspan: 2,
                            },
                            {
                              label: 'Órgão',
                              value: fichaFinHeader.orgao,
                              width: '30%',
                              colspan: 1,
                            },
                            {
                              label: 'Regime',
                              value: extraiSomenteTexto(fichaFinHeader.regime),
                              width: '15%',
                              colspan: 1,
                            },
                          ]"
                          :key="index"
                          :style="{ width: col.width }"
                          :colspan="col.colspan"
                          class="head-line mt-5 pt-5 vertical-align-middle">
                          <label>{{ col.label }}:&nbsp;</label>
                          <span class="font-light">{{
                            verificaValor(col.value)
                          }}</span>
                        </th>
                      </tr>
                      <tr
                        style="border: none !important"
                        class="md:vertical-align-middle">
                        <th
                          v-for="(col, index) in [
                            {
                              label: 'Matrícula',
                              value: fichaFinHeader.matricula,
                              width: '30%',
                              colspan: 1,
                            },
                            {
                              label: 'CPF',
                              value: fichaFinHeader.cpf,
                              width: '25%',
                              colspan: 2,
                            },
                            {
                              label: 'Unidade de Trabalho',
                              value: extraiSomenteTexto(fichaFinHeader.utb),
                              width: '30%',
                              colspan: 1,
                            },
                            {
                              label: 'D. S. FAM.',
                              value: fichaFinHeader.ds_fam,
                              width: '15%',
                              colspan: 1,
                            },
                          ]"
                          :key="index"
                          :style="{ width: col.width }"
                          :colspan="col.colspan"
                          class="head-line vertical-align-top">
                          <label>{{ col.label }}:&nbsp;</label>
                          <span class="font-light">{{
                            verificaValor(col.value)
                          }}</span>
                        </th>
                      </tr>
                      <tr
                        style="border: none !important"
                        class="md:vertical-align-middle">
                        <th
                          v-for="(col, index) in [
                            {
                              label: 'CLF',
                              value: extraiSomenteTexto(fichaFinHeader.clf),
                              width: '30%',
                              colspan: 1,
                            },
                            {
                              label: 'Data de Admissão',
                              value: fichaFinHeader.data_admissao,
                              width: '20%',
                              colspan: 2,
                            },
                            {
                              label: 'Situação',
                              value: fichaFinHeader.situacao,
                              width: '30%',
                              colspan: 1,
                            },
                            {
                              label: 'D. I. Rend.',
                              value: fichaFinHeader.di_rend,
                              width: '15%',
                              colspan: 1,
                            },
                          ]"
                          :key="index"
                          :style="{ width: col.width }"
                          :colspan="col.colspan"
                          class="head-line vertical-align-top">
                          <label>{{ col.label }}:&nbsp;</label>
                          <span class="font-light">{{
                            verificaValor(col.value)
                          }}</span>
                        </th>
                      </tr>
                      <tr
                        style="border: none !important"
                        class="md:vertical-align-middle">
                        <th
                          v-for="(col, index) in [
                            {
                              label: 'Símbolo',
                              value: fichaFinHeader.simbolo,
                              width: '30%',
                              colspan: 1,
                            },
                            {
                              label: 'T. S. Aposentadoria',
                              value: fichaFinHeader.ts_apos,
                              width: '25%',
                              colspan: 2,
                            },
                            {
                              label: 'PASEP',
                              value: fichaFinHeader.pasep,
                              width: '30%',
                              colspan: 1,
                            },
                            {
                              label: 'D. Excp.',
                              value: fichaFinHeader.d_excp,
                              width: '15%',
                              colspan: 1,
                            },
                          ]"
                          :key="index"
                          :style="{ width: col.width }"
                          :colspan="col.colspan"
                          class="head-line vertical-align-top">
                          <label>{{ col.label }}:&nbsp;</label>
                          <span class="font-light">{{
                            verificaValor(col.value)
                          }}</span>
                        </th>
                      </tr>
                      <tr>
                        <th
                          class="head-last-line"
                          style="width: 100%"
                          colspan="6">
                          <label>Saída:&nbsp; </label>
                          <span class="font-light">{{
                            verificaValor(
                              extraiSomenteTexto(
                                fichaFinHeader.data_afastamento,
                              ),
                            )
                          }}</span>
                        </th>
                      </tr>
                    </thead>
                  </table>
                  <!-- </div> -->
                </div>
              </div>
              <div class="row">
                <div class="col d-flex flex-column overflow-x-scroll mg">
                  <table
                    id="lancamentosBody"
                    class="ficha_financeira_table table table-striped custom-striped-table overflow-scroll bg-white"
                    style="color: #071d42">
                    <thead class="lancamentosHeader">
                      <tr class="text-center">
                        <th
                          style="width: 6%"
                          class="meses_border border-y-none border-x-1">
                          <span class="inline-flex">Código - Descrição</span>
                        </th>
                        <th
                          v-for="(mes, index) in meses"
                          :key="index"
                          class="meses meses_border border-y-none border-x-1">
                          {{ mes.mesEmFormatoDeTitulo }}
                        </th>
                        <th
                          style="width: 6%"
                          class="meses_border border-y-none border-x-1">
                          Total do Período
                        </th>
                      </tr>
                    </thead>
                    <tbody id="body">
                      <template
                        v-for="(lancamento, tipoLancamento) in fichaFinBody">
                        <template
                          v-for="(
                            discriminacao, id
                          ) in lancamento.listaDeDiscriminacoes">
                          <tr
                            :key="id"
                            class="p-4 light-row vertical-align-middle">
                            <td
                              class="text-left border-right-1 body_cells_border border-right-1">
                              {{ verificaValor(discriminacao.denominacao) }}
                            </td>
                            <td
                              v-for="(mes, index) in meses"
                              :key="index"
                              class="text-right body_cells_border border-x-1 vertical-align-middle">
                              {{
                                retornaDefaultParaValorInexistente(
                                  lancamento,
                                  [
                                    'lancamentosAgrupadosPorMeses',
                                    mes.indiceCorrespondente,
                                    'lancamentosPorCodigo',
                                    id,
                                    'valor',
                                  ],
                                  'R$ 0,00',
                                )
                              }}
                            </td>
                            <td class="text-left">
                              {{
                                verificaValor(discriminacao.valorTotalFormatado)
                              }}
                            </td>
                          </tr>
                        </template>
                        <tr
                          id="sumario"
                          :key="tipoLancamento"
                          class="totais font-semibold text-right vertical-align-middle">
                          <td class="body_cells_border border-x-1">
                            Total de {{ tipoLancamento }}
                          </td>
                          <th
                            v-for="(mes, index) in meses"
                            :key="index"
                            class="total body_cells_border border-x-1 vertical-align-middle">
                            {{
                              retornaDefaultParaValorInexistente(
                                lancamento,
                                [
                                  'lancamentosAgrupadosPorMeses',
                                  mes.indiceCorrespondente,
                                  'valorTotalMesFormatado',
                                ],
                                'R$ 0,00',
                              )
                            }}
                          </th>
                          <td>{{ lancamento.valorTotalFormatado }}</td>
                        </tr>
                      </template>

                      <tr class="font-bold valores-liquidos">
                        <td colspan="1" class="text-right">Valor Líquido</td>
                        <td
                          v-for="(
                            valorLiquido, index
                          ) in fichaFinFooter.valoresLiquidos"
                          :key="index"
                          class="body_cells_border border-x-1 text-right">
                          {{ valorLiquido }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <!-- <div class="row py-4 d-flex justify-content-around flex-row-reverse">
              <div>
                <PieChart v-if="liquido && descontos" :chartData="pieChartData" :options="pieChartOptions"
                  style="width: 250px" />
              </div>
              <div class="d-flex align-items-center">
                <qrcode-vue :value="qrCodeUrl" :size="qrCodeSize" level="H"></qrcode-vue>

                <div class="py-3 ml-3" style="max-width: 40%; font-size: 12px">
                  Aponte a câmera do seu celular para validar seu contracheque
                  <br />
                  Ou acesse a validação atráves da url: <br />
                  <a :href="qrCodeUrl"><b>{{ qrCodeUrl }}</b></a>
                </div>
              </div>
            </div> -->
          </template>
        </Card>
      </BlockUI>
    </div>
  </div>
</template>
<script>
import { saveAs } from 'file-saver'
import get from 'lodash/get'
import MatriculaService from '@/service/MatriculaService.js'
import FichaFinanceiraService from '@/service/FichaFinanceiraService.js'
import { selecionarObjetoPadrao } from '@/utils/SelecionarObjetoPadrao'
import { matriculaStore } from '@/stores/matricula'

export default {
  setup() {
    const store = new matriculaStore()
    return { store }
  },

  data() {
    return {
      matriculasList: [],
      exerciciosList: [],
      exerciciosInicioList: [],
      exerciciosFimList: [],
      folhasList: [],
      exercicio: null,
      exercicioInicio: null,
      exercicioFim: null,
      folha: null,
      matricula: null,
      loading: false,
      totalRecords: 0,
      lazyParams: {},
      loadingDetail: false,
      loadingPdf: false,
      pdfBlobUrl: null,
      pdfBlob: null,

      meses: [],

      fichaFinHeader: {
        ano_referencia: '',
        cargo: '',
        clf: '',
        complemento: '',
        cpf: '',
        d_excp: '',
        data_admissao: '',
        data_afastamento: '',
        di_rend: '',
        ds_fam: '',
        exercicio: '',
        logo: '',
        lotacao: '',
        matricula: '',
        nome: '',
        orgao: '',
        pasep: '',
        regime: '',
        simbolo: '',
        situacao: '',
        ts_apos: '',
        utb: '',
      },

      fichaFinBody: {
        vantagens: {
          listaDeDiscriminacoes: {
            id: {
              id: '',
              discriminacao: '',
              valorTotalFormatado: '',
            },
          },
          lancamentosAgrupadosPorMeses: {
            mesNumero: {
              lancamentosPorCodigo: {
                idTransacao: {
                  idLancamento: '',
                  discriminacao: '',
                  prazo: '',
                  tipo: '',
                  valor: '',
                },
              },
            },
          },
          valorTotalFormatado: '',
        },
        descontos: {
          listaDeDiscriminacoes: {
            id: {
              id: '',
              discriminacao: '',
              valorTotalFormatado: '',
            },
          },
          lancamentosAgrupadosPorMeses: {
            mesNumero: {
              lancamentosPorCodigo: {
                idTransacao: {
                  idLancamento: '',
                  discriminacao: '',
                  prazo: '',
                  tipo: '',
                  valor: '',
                },
              },
            },
          },
          valorTotalFormatado: '',
        },
      },

      showFichaFinanceira: false,

      fichaFinFooter: {
        valoresLiquidos: [],
      },

      contracheque: {},
      liquido: 0,
      descontos: 0,
      showLoading: false,
      imagemSrc: null,
      qrCodeSize: 130,
      qrCodeUrl: '',
      home: { icon: 'pi pi-home', to: '/' },
      breadcrumbitems: [
        {
          label: 'Ficha Financeira',
          to: { name: 'ContraChequeLista' },
        },
      ],
      activeIndex: 0,
      tabIndex: {
        'consignacao-detalhe': 0,
        'consignacao-parcelas': 1,
      },
    }
  },

  computed: {
    rowsPerPageOptions() {
      if (this.totalRecords < 5) {
        return null
      }
      return [5, 10, 25]
    },
  },

  watch: {
    'store.matriculaSelecionada': function () {
      this.carregarDados()
    },
  },

  created() {
    this.geralistaDeMeses()
    this.matriculaService = new MatriculaService(this.$http)
    this.fichaFinanceiraService = new FichaFinanceiraService(this.$http)
  },

  mounted() {
    this.loading = true
    this.carregarDados()
  },
  beforeDestroy() {
    URL.revokeObjectURL(this.pdfBlobUrl)
  },

  methods: {
    listarExericiosFimESelecionarExericioFim() {
      this.exerciciosFimList = this.exerciciosInicioList.filter(
        (year) => year >= this.exercicioInicio,
      )
      this.exercicioFim = this.exerciciosInicioList.find(
        (year) => year == this.exercicioInicio,
      )
    },
    beforeDestroy() {
      URL.revokeObjectURL(this.pdfBlobUrl)
    },
    downloadPdf() {
      saveAs(this.pdfBlob, 'FICHA_FINANCEIRA.pdf')
    },

    obterFichaFinanceira() {
      this.fichaFinanceiraService
        .obterFichaFinanceira(
          this.store.matriculaSelecionada.id,
          this.exercicio,
        )
        .then((res) => {
          this.showFichaFinanceira = true

          this.fichaFinHeader = res.fichaFinanceira.header
          this.fichaFinBody = res.fichaFinanceira.body
          this.fichaFinFooter.valoresLiquidos =
            res.fichaFinanceira.footer.valoresLiquidos.valoresFormatados
        })
        .catch((err) => {
          this.showToastErro(err)
        })
    },

    carregarDados() {
      this.loading = true
      this.matricula = this.store.matriculaSelecionada
      this.carregarCombos()
    },

    geralistaDeMeses() {
      const locales = ['pt-br']
      const format = 'long'
      const meses = Array.from({ length: 12 }, (_, i) => {
        const date = new Date(0, i)
        const mes = new Intl.DateTimeFormat(locales, { month: format }).format(
          date,
        )
        const mesEmFormatoDeTitulo = mes.charAt(0).toUpperCase() + mes.slice(1)
        return {
          indiceCorrespondente: i + 1,
          mesEmFormatoDeTitulo: mesEmFormatoDeTitulo,
        }
      })
      this.meses = meses
    },

    removerMascara(data) {
      return data.replace(/\D/g, '')
    },

    retornaDefaultParaValorInexistente(objeto, chaves, valorDefault) {
      const chaveEncadeadas = chaves.join('.')
      return get(objeto, chaveEncadeadas, valorDefault)
    },

    realizarDownload() {
      window.print()
    },

    retornar() {
      this.$router.back()
    },

    verificaValor(valor) {
      return valor ? valor : '-'
    },

    extraiSomenteTexto(label) {
      const separated = label.split(' - ')
      return separated[separated.length - 1]
    },

    carregarCombos() {
      this.carregarComboExercicio()
      this.carregarComboFolha()
    },

    carregarComboExercicio() {
      if (this.matricula.id !== null) {
        this.fichaFinanceiraService
          .getListaExercicios(this.matricula.id)
          .then((res) => {
            this.exerciciosList = res
            this.exerciciosInicioList = res
            this.exerciciosFimList = res
          })
          .catch((err) => {
            this.showToastErro(err)
          })
      }
    },

    carregarComboFolha() {
      if (this.matricula.id !== null) {
        this.fichaFinanceiraService
          .getListaFolhas(this.matricula.id)
          .then((res) => {
            this.folhasList = res
            this.folha = selecionarObjetoPadrao(this.folhasList)
          })
          .catch((err) => {
            const mensagem = err.response
              ? err.response.data.message
              : 'Erro de conexão com a aplicação.'
            this.limpar()
            this.$toast.add({
              severity: 'error',
              summary: mensagem,
              life: 10000,
            })
          })
      }
    },

    limpar() {
      this.loading = false
    },

    showToastErro(err) {
      const mensagem = err.response
        ? err.response.data.message
        : 'Erro de conexão com a aplicação.'
      this.limpar()
      this.$toast.add({
        severity: 'error',
        summary: mensagem,
        life: 10000,
      })
    },
  },
}
</script>
<style>
.div-center {
  margin: auto;
}

html {
  min-width: 400px !important;
}

body {
  display: flex;
  flex-direction: column;
  min-width: 400px !important;
  overflow: scroll;
}

.card-desktop {
  min-width: 400px !important;
}

#header span {
  font-weight: normal;
}

.exercicio_label {
  font-family: Arial, Helvetica, sans-serif;
}

table .meses {
  vertical-align: middle !important;
}

table .total {
  vertical-align: middle !important;
}

table .head-line {
  vertical-align: middle !important;
}

table .head-line span {
  padding-right: 2px;
}

@media (max-width: 1750px) {
  table .head-line {
    vertical-align: top !important;
    padding: 5px;
  }

  table .head-line span {
    display: flex;
    flex-direction: row;
    padding-right: 5px;
  }
}

@media (max-width: 1000px) {
  * {
    font-size: small;
  }

  table .head-line span,
  .head-last-line span {
    font-size: 11px !important;
  }
}

@media (max-width: 600px) {
  * {
    font-size: small;
  }

  table .head-line label,
  .head-last-line label {
    font-size: 11px !important;
  }

  table .head-line span,
  .head-last-line span {
    font-size: 10px !important;
  }

  .table td,
  .table .total {
    font-size: 10px !important;
  }
}

.table td {
  vertical-align: middle;
}

.ficha_financeira_table {
  width: 100%;
  font-size: 12px;
}

.meses_border {
  border-color: #224683;
}

.body_cells_border {
  border-color: rgb(202, 195, 195);
}

.custom-striped-table {
  border-spacing: 0 10px;
  border-collapse: separate;
}

.light-row {
  background-color: #efefef !important;
}

.dark-row {
  background-color: var(--bs-table-accent-bg);
}

.custom-row {
  background-color: var(--bs-warning-bg);
}

.totais {
  background-color: #e3e3e3 !important;
}

.valores-liquidos {
  background-color: #c9d1d6 !important;
  font-weight: bold;
}

.lancamentosHeader {
  background-color: #071d42;
  color: white;
}

#lancamentosBody {
  background-color: #efefef;
}

@media print {
  ::v-deep .p-button,
  .p-button.p-button-icon-only.p-button-rounded,
  .p-breadcrumb {
    display: none;
  }

  @page {
    margin-left: 0.5in;
    margin-right: 0.5in;
    margin-top: 0;
    margin-bottom: 0;
  }

  .contracheque {
    background-color: white;
    height: 100%;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0 5px 0 5px;
    font-size: 14px;
    line-height: 18px;
  }

  .lancamentosHeader tr,
  .lancamentosHeader th {
    background-color: inherit !important;
    color: inherit !important;
  }

  td {
    vertical-align: center !important;
  }

  #lancamentosBody tr,
  #lancamentosBody td {
    background-color: inherit !important;
  }
}
</style>
